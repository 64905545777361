import { graphql, Link, withPrefix } from 'gatsby';
import { useState } from 'react';
import * as React from 'react';

import { A } from '../components/a';
import Footer from '../components/footer';
import Navbar from '../components/navbar';
import Pagination from '../components/pagination';
import { paginate } from '../helpers/pagination';
import { Location, SiteMetadata } from '../models';
import EmailPopup from '../components/email-popup';
import Seo from '../components/seo'

const PAGE_SIZE = 12;

interface MarkdownRemark {
  excerpt: string;
  fields: {
    slug: string;
  };
  frontmatter: {
    date: string;
    title: string;
    description: string;
    author: string;
  };
}

interface Props {
  data: {
    site: SiteMetadata;
    allMarkdownRemark: {
      nodes: MarkdownRemark[];
    };
  };
  location: Location;
  pageContext: {
    categoryName: string;
  };
}

const BlogCategory = ({ data, location, pageContext }: Props) => {
  const [currentPage, setCurrentPage] = useState(1);

  const { categoryName } = pageContext;
  const allPosts = data.allMarkdownRemark.nodes;
  const totalPages = Math.ceil(allPosts.length / PAGE_SIZE);
  const posts = paginate(allPosts, currentPage, PAGE_SIZE);

  return (
    <div id="main_content">
      <Seo title='Blog Categories'/>
      <Navbar dark={true} />
      <EmailPopup/>

      <section className="page-banner blog-details-banner">
        <div className="container">
          <div className="page-title-wrapper">
            <h1 className="page-title">Category "{categoryName}"</h1>

            <ul className="bradcurmed">
              <li>
              <Link to="/">
                    Home
                </Link>
              </li>
              <li>
                <Link to="/blog">
                    Blog
                </Link>
              </li>
              <li>{categoryName}</li>
            </ul>
          </div>
        </div>

        <svg
          className="circle"
          data-parallax='{"x" : -200}'
          xmlns="http://www.w3.org/2000/svg"
          width="950px"
          height="950px"
        >
          <path
            fillRule="evenodd"
            stroke="rgb(250, 112, 112)"
            strokeWidth="100px"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            opacity="0.051"
            fill="none"
            d="M450.000,50.000 C670.914,50.000 850.000,229.086 850.000,450.000 C850.000,670.914 670.914,850.000 450.000,850.000 C229.086,850.000 50.000,670.914 50.000,450.000 C50.000,229.086 229.086,50.000 450.000,50.000 Z"
          />
        </svg>

        <ul className="animate-ball">
          <li className="ball"></li>
          <li className="ball"></li>
          <li className="ball"></li>
          <li className="ball"></li>
          <li className="ball"></li>
        </ul>
      </section>

      <div className="blog-post-archive">
        <div className="container">
          <div className="row">
            {posts.map(post => (
              <PostListItem key={post.fields.slug} post={post} />
            ))}
          </div>

          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onClick={(p: number) => setCurrentPage(p)}
          />
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default BlogCategory;

export const pageQuery = graphql`
  query BlogCategoryQuery($categoryName: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { categories: { eq: $categoryName }, feed: { eq: "blog" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          author
        }
      }
    }
  }
`;

interface PostListItemProps {
  post: MarkdownRemark;
}

function PostListItem({ post }: PostListItemProps) {
  return (
    <div className="col-lg-4 col-md-6">
      <article className="post-post-grid">
        <div className="feature-image">
          <Link to={post.fields.slug}>
            <img src={withPrefix('media/blog/13.jpg')} alt="" />
          </Link>
        </div>
        <div className="blog-content">
          <ul className="post-meta">
            <li>
              <A>{post.frontmatter.date}</A>
            </li>
          </ul>

          <h3 className="entry-title">
            <Link to={post.fields.slug}>{post.frontmatter.title}</Link>
          </h3>

          <div className="author">
            <img src={withPrefix('media/blog/auth2.jpg')} alt="author" />
            {post.frontmatter.author}
          </div>
        </div>
      </article>
    </div>
  );
}
